import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Image from "../image"

import styles from "./sidebar.module.css"

const Sidebar = ({ siteTitle }) => (
	<React.Fragment>
		<div className={styles.outer_container}>
			<div className={styles.top_container}>
				<div className={styles.image_container}>
					<Link to="/"><Image filename="profile.jpg" /></Link>
				</div>
				<div className={styles.border}></div>
			</div>

			<div className={styles.bottom_container}>
				<div className={styles.pc_menu}>
					<nav>
						<ul>
							<li><Link to="/" activeClassName={styles.active_menu}>ホーム</Link></li>
							<li><Link to="/profile" activeClassName={styles.active_menu}>プロフィール</Link></li>
							<li><Link to="/achievements" activeClassName={styles.active_menu}>実績</Link></li>
							<li><Link to="/mission" activeClassName={styles.active_menu}>理念</Link></li>
							<li><Link to="/business" activeClassName={styles.active_menu}>事業内容</Link></li>
							<li><Link to="/contact" activeClassName={styles.active_menu}>お問い合わせ</Link></li>
						</ul>
					</nav>
				</div>
			</div>
		</div>
	</React.Fragment>
)

Sidebar.propTypes = {
  siteTitle: PropTypes.string,
}

Sidebar.defaultProps = {
  siteTitle: ``,
}

export default Sidebar
