import React from 'react'

const Footer = () => (

	<footer>
	Copyright &copy; 2019 Masato Kaneko
	<div>Icons made by <a href="https://www.flaticon.com/authors/freepik" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
	</footer>

)
export default Footer
